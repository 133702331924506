import * as React from 'react'
import { Link } from 'gatsby'

import Layout from '../layouts/layout'
import SEO from '../components/SEO'
import Page from '../components/Page'

const PageTwo = () => (
  <Layout>
    <SEO title="page2" />
    <Page>
      <h1>Hi from the second page</h1>
      <p>Welcome to page 2</p>
      <ul>
        <li>
          <Link to="/a-markdown-page/">Show me some Markdown!</Link>
        </li>
        <li>
          <Link to="/">Take me back home.</Link>
        </li>
      </ul>
    </Page>
  </Layout>
)

export default PageTwo
